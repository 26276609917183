/* eslint-disable react/jsx-filename-extension,no-unused-vars,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useNavigate } from '@reach/router';
import { MetaData } from '../components/common/meta';
import AuthorSvg from '../images/blog/author-sign.svg';
import '../common-styles/blog.scss';
import ShareIcons from '../components/blogCards/postShare';
import BlogLayout from '../components/layout/blogLayout';
import RelatedPosts from '../components/relatedPostsBlog';
import Breadcrumb from '../components/breadcrumb';
import { saveURLParamToSession } from '../utils';

/**
 * Single post view (blog/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = (props) => {
  const navigate = useNavigate();
  const { data, location } = props;
  const post = data.ghostPost;
  const relatedPosts = data.allGhostPost.edges.filter(
    ({ node }) => node.id !== post.id
  );

  useEffect(() => {
    saveURLParamToSession();
    handleLinkClick();
  }, []);

  const handleLinkClick = () => {
    const links = document.querySelectorAll('.content-body a');
    if (links) {
      [...links].forEach((link) => {
        link.setAttribute('target', '_blank');
        // eslint-disable-next-line no-param-reassign
        link.style.display = 'inline';
      });
    }
  };

  const updateArticle = () => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop)
      || document.body.scrollTop;

    const article = document.querySelector(
      `#post-content-container`
    );

    const svg = document.querySelector(
      `.share-sticky button.reading-progress svg circle`
    );

    const rect = article.getBoundingClientRect();
    const articleTop = rect.top + scrollTop - 500;

    if (articleTop > scrollTop) {
      // console.log('remove class');
    } else if (
      scrollTop >= articleTop
      && articleTop + article.offsetHeight > scrollTop
    ) {
      const dashoffsetValue = 100 * (1 - (scrollTop - articleTop) / article.offsetHeight);
      svg.setAttribute('stroke-dashoffset', `${dashoffsetValue}`);
      // console.log('dashoffsetValue', dashoffsetValue);
    }
  };

  const scrollToTop = () => {
    const article = document.querySelector(`section.post-full-content`);
    article.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    document.addEventListener('scroll', updateArticle);
    return () => document.removeEventListener('scroll', updateArticle);
  }, []);

  const handleAuthorClick = (authorSlug) => {
    navigate(`/blog/author/${authorSlug}/`);
  };

  return (
    <>
      <MetaData data={data} location={location} type="article" />
      <Helmet>
        <style type="text/css">{`${post.codeinjection_styles}`}</style>
      </Helmet>
      <BlogLayout location={location}>
        <Breadcrumb
          type="post"
          category={post.primary_tag}
          title={post.title}
        />
        <div className="">
          <article className="content">
            <figure className="post-feature-image">
              <img src={post.feature_image} alt={post.title} />
            </figure>
            <section className="post-full-content container-new">
              <h6 className="content-tag">
                <Link to={`/blog/category/${post.primary_tag.slug}`}>
                  {post.primary_tag.name}
                </Link>
              </h6>
              <h1 className="content-title" id="post-title">
                {post.title}
              </h1>
              <main id="post-content-container">
                <div className="content-meta meta-mobile">
                  <div className="content-author-meta d-flex d-md-none">
                    <div className="content-meta">
                      <p>{post.published_at}</p>
                      {' '}
                      <p>•</p>
                      <p>
                        {post.reading_time}
                        {' '}
                        mins read
                      </p>
                    </div>
                  </div>
                </div>
                <div className="content-meta">
                  <div className="d-flex" style={{ alignItems: 'center' }}>
                    <div className="content-author-image top cursor-pointer" onClick={() => handleAuthorClick(post.primary_author.slug)}>
                      <img
                        src={post.primary_author.profile_image || AuthorSvg}
                        alt={post.primary_author.name}
                      />
                    </div>
                    <div className="content-author-meta top">
                      <p className="cursor-pointer" onClick={() => handleAuthorClick(post.primary_author.slug)}>{post.primary_author.name}</p>
                      <div className="content-meta d-none d-md-flex">
                        <p>{post.published_at}</p>
                        {' '}
                        <p>•</p>
                        <p>
                          {post.reading_time}
                          {' '}
                          mins read
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="share-icons-container d-block d-md-none mobile">
                    <ShareIcons post={post} />
                  </div>
                </div>
                <div className="post-content-container">
                  <div className="share-container d-none d-md-block">
                    <div className="share-sticky">
                      <ShareIcons post={post} scrollToTop={scrollToTop} />
                    </div>
                  </div>
                  <section
                    className="content-body load-external-scripts"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </div>
              </main>
              <div className="content-meta" style={{ marginTop: '25px' }}>
                <div className="content-author-image" onClick={() => handleAuthorClick(post.primary_author.slug)}>
                  <img
                    src={post.primary_author.profile_image || AuthorSvg}
                    alt={post.primary_author.name}
                  />
                </div>
                <div className="content-author-meta">
                  <p>
                    <Link to={`/blog/author/${post.primary_author.slug}`}>
                      {post.primary_author.name}
                    </Link>
                  </p>
                  <div className="content-meta">
                    <p>{post.primary_author.bio}</p>
                  </div>
                </div>
              </div>
            </section>
          </article>
          <div
            className={`container popular-posts ${
              relatedPosts.length ? '' : 'd-none'
            }`}
          >
            <hr />
            <h4 className="title">More From Klub Blogs</h4>
            <RelatedPosts relatedPosts={{ edges: relatedPosts }} />
          </div>
        </div>
      </BlogLayout>
    </>
  );
};

export default Post;

export const postQuery = graphql`
  query($slug: String!, $primary_tag: String!) {
    ghostPost(slug: { eq: $slug }) {
      id
      slug
      feature_image
      primary_author {
        profile_image
        name
        cover_image
        slug
        bio
      }
      primary_tag {
        name
        slug
      }
      published_at(formatString: "MMM DD, YYYY")
      title
      html
      reading_time
      codeinjection_styles
    }
    allGhostPost(
      sort: { order: DESC, fields: published_at }
      filter: { primary_tag: { slug: { eq: $primary_tag } } }
    ) {
      edges {
        node {
          id
          slug
          feature_image
          primary_author {
            name
            slug
          }
          primary_tag {
            name
            slug
          }
          published_at(formatString: "MMM DD, YYYY")
          title
        }
      }
    }
  }
`;
