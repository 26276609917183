import React from 'react';
import FbIcon from '../../../images/blog/share-fb.svg';
import TwitterIcon from '../../../images/blog/share-twitter.svg';
import LinkedinIcon from '../../../images/blog/share-linkedin.svg';
import UpArrowIcon from '../../../images/blog/up-arrow.svg';
import '../styles.scss';

const ShareIcons = ({ scrollToTop, post }) => {
  // console.log(post);
  const handleShare = (type) => {
    const { title } = post;
    switch (type) {
      case 'fb':
        {
          const url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
          window.open(url, '_blank');
        }
        break;
      case 'twitter':
        {
          const url = `https://twitter.com/intent/tweet?url=${window.location.href}&text=${title} @klubworks`;
          window.open(url, '_blank');
        }
        break;
      case 'linkedin':
        {
          const url = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=&summary=${title}&source=`;
          window.open(url, '_blank');
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="share-icons-container">
      <button
        type="button"
        className="share-btn reading-progress"
        onClick={scrollToTop}
      >
        <img src={UpArrowIcon} alt="facebook share" />
        <svg x="0px" y="0px" width="36px" height="36px" viewBox="0 0 36 36">
          <circle
            fill="none"
            stroke="#1518af"
            strokeWidth="2"
            cx="18"
            cy="18"
            r="16"
            strokeDasharray="100 100"
            strokeDashoffset="100"
            transform="rotate(-90 18 18)"
          />
        </svg>
      </button>
      <button
        type="button"
        className="share-btn"
        onClick={() => handleShare('fb')}
      >
        <img src={FbIcon} alt="facebook share" />
      </button>
      <button
        type="button"
        className="share-btn"
        onClick={() => handleShare('twitter')}
      >
        <img src={TwitterIcon} alt="Twitter share" />
      </button>
      <button
        type="button"
        className="share-btn"
        onClick={() => handleShare('linkedin')}
      >
        <img src={LinkedinIcon} alt="Linkedin share" />
      </button>
    </div>
  );
};

export default ShareIcons;
